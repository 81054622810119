import * as React from "react";
import { ClipboardIcon, DollarSign, Wallet } from "lucide-react";
import { Button } from "../components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../components/ui/card";

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../components/ui/tooltip";
import {
  TransactionType,
  useApproveDistributionMutation,
  useGetDistributionsToApproveQuery,
  useGetTransactionsHistoryQuery,
  useGetOrgAndUserQuery,
  useRejectDistributionMutation,
  AdminRole,
  GetTransactionsHistoryQuery,
} from "../generated/graphql";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../components/ui/table";
import { Badge } from "../components/ui/badge";
import { useNavigate } from "react-router-dom";
import { Toaster } from "../components/ui/toaster";
import { useToast } from "../components/ui/use-toast";
import { useEffect } from "react";
import { formatNumber, get50BpsAndFormat } from "../lib/formatNumbers";
import { Navbar } from "../components/Navbar";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from "../components/ui/sheet";
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSlot,
} from "../components/ui/input-otp";
import { REGEXP_ONLY_DIGITS_AND_CHARS } from "input-otp";
import { formatDate } from "../lib/formatDate";
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "../components/ui/pagination";

export function Dashboard() {
  const navigate = useNavigate();
  const { toast } = useToast();
  const [distributionId, setDistributionId] = React.useState<number | null>(
    null
  );
  const [transactionPage, setTransactionPage] = React.useState<number>(1);
  const [{ data: userData, error: userError }, refetchUser] =
    useGetOrgAndUserQuery({
      requestPolicy: "network-only",
    });
  const [
    { data: distributionsToApproveData, error: distributionsToApproveError },
    refetchDistributionsToApprove,
  ] = useGetDistributionsToApproveQuery({
    requestPolicy: "network-only",
  });
  const [
    { data: transactionsData, error: transactionsErrors },
    refetchTransactions,
  ] = useGetTransactionsHistoryQuery({
    variables: {
      page: transactionPage,
    },
    requestPolicy: "network-only",
  });
  const [{ data: approvalData, error: approvalError }, approve] =
    useApproveDistributionMutation();
  const [{ data: rejectionData, error: rejectionError }, reject] =
    useRejectDistributionMutation();

  const wallet = userData?.getOrganization.ethWalletAddress;
  const balance = userData?.getOrganization.balance;
  const userRole = userData?.getUser.role;
  const has2fa = userData?.getUser.has2fa;

  const distributionsToApprove =
    distributionsToApproveData?.getDistributionsToApprove;
  const transactions = transactionsData?.getTransactionsHistory;

  // toast errors
  useEffect(() => {
    if (userError) {
      toast({
        title: "Error",
        description: userError.message,
        variant: "destructive",
      });
    }

    if (distributionsToApproveError) {
      toast({
        title: "Error",
        description: distributionsToApproveError.message,
        variant: "destructive",
      });
    }

    if (transactionsErrors) {
      toast({
        title: "Error",
        description: transactionsErrors.message,
        variant: "destructive",
      });
    }

    if (approvalError) {
      toast({
        title: "Error",
        description: approvalError.message,
        variant: "destructive",
      });
    }

    if (rejectionError) {
      toast({
        title: "Error",
        description: rejectionError.message,
        variant: "destructive",
      });
    }
  }, [
    userError,
    distributionsToApproveError,
    transactionsErrors,
    approvalError,
    rejectionError,
    toast,
  ]);

  // on approve
  useEffect(() => {
    if (approvalData) {
      toast({
        title: "Success",
        description: "Approved successfully",
        variant: "default",
      });
      refetchDistributionsToApprove();
      refetchTransactions();
      refetchUser();
    }
  }, [
    approvalData,
    refetchDistributionsToApprove,
    refetchTransactions,
    refetchUser,
    toast,
  ]);

  // on reject
  useEffect(() => {
    if (rejectionData) {
      toast({
        title: "Success",
        description: "Rejected successfully",
        variant: "default",
      });
      refetchDistributionsToApprove();
      refetchTransactions();
    }
  }, [
    refetchDistributionsToApprove,
    refetchTransactions,
    rejectionData,
    toast,
  ]);

  return (
    <div className="flex min-h-screen w-full flex-col">
      <Navbar />
      <main className="flex flex-1 flex-col gap-4 p-4 md:gap-8 md:p-8">
        <div className="grid gap-2">
          <div>Welcome,</div>
          <div className="text-3xl font-bold">
            {userData?.getOrganization.name}
          </div>
        </div>
        <div className="grid gap-2 md:grid-cols-1 md:gap-8 lg:grid-cols-2">
          <Card x-chunk="A card showing the total revenue in USD and the percentage difference from last month.">
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <CardTitle className="text-sm font-medium">Balance</CardTitle>
              <DollarSign className="h-4 w-4 text-muted-foreground" />
            </CardHeader>
            <CardContent>
              {balance?.map((b, idx) => (
                <div key={idx} className="text-2xl font-bold">
                  {b.mint} ${formatNumber(b.credit ?? "0")}
                </div>
              ))}
              <p className="text-xs text-muted-foreground">Current balance</p>
            </CardContent>
          </Card>
          {balance?.findIndex((b) => b.onHold !== "0") !== -1 && (
            <Card x-chunk="A card showing the total revenue in USD and the percentage difference from last month.">
              <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                <CardTitle className="text-sm font-medium">On Hold</CardTitle>
                <DollarSign className="h-4 w-4 text-muted-foreground" />
              </CardHeader>
              <CardContent>
                {balance
                  ?.filter((b) => b.onHold !== "0")
                  .map((b, idx) => (
                    <div key={idx} className="text-2xl font-bold">
                      {b.mint} ${formatNumber(b.onHold ?? "0")}
                    </div>
                  ))}
                <p className="text-xs text-muted-foreground">
                  Current balance on Hold
                </p>
              </CardContent>
            </Card>
          )}
          <Card>
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <CardTitle className="text-sm font-medium">
                Wallet Address
                <p className="text-xs text-muted-foreground">
                  Send USDT-ERC20 to this address to receive credit
                </p>
              </CardTitle>
              <Wallet className="h-4 w-4 text-muted-foreground" />
            </CardHeader>
            <CardContent>
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      variant="outline"
                      onClick={() =>
                        navigator.clipboard.writeText(wallet ?? "")
                      }
                    >
                      {wallet}
                      <ClipboardIcon className="ml-2 h-4 w-4" />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>Click to copy</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </CardContent>
          </Card>
        </div>
        {/* <div className="grid gap-4 md:gap-8 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2"> */}
        {(distributionsToApprove?.length ?? 0) > 0 && (
          <Card className="xl:col-span-2">
            <CardHeader className="flex flex-row items-center">
              <div className="grid gap-2">
                <CardTitle>Transactions For Approval</CardTitle>
              </div>
              <Button asChild size="sm" className="ml-auto gap-1">
                {/* <Link href="#">
                  View All
                  <ArrowUpRight className="h-4 w-4" />
                </Link> */}
              </Button>
            </CardHeader>
            <CardContent>
              <Sheet
                open={distributionId !== null}
                onOpenChange={() => {
                  setDistributionId(null);
                }}
              >
                <SheetContent side="bottom">
                  <SheetHeader>
                    <SheetTitle>MFA</SheetTitle>
                  </SheetHeader>
                  <div className="grid gap-4 py-4">
                    <div className="grid grid-cols-4 items-center gap-4">
                      <InputOTP
                        maxLength={6}
                        pattern={REGEXP_ONLY_DIGITS_AND_CHARS}
                        onComplete={async (value) => {
                          if (!distributionId) return;
                          await approve({ id: distributionId, secret: value });
                        }}
                      >
                        <InputOTPGroup>
                          <InputOTPSlot index={0} />
                          <InputOTPSlot index={1} />
                          <InputOTPSlot index={2} />
                          <InputOTPSlot index={3} />
                          <InputOTPSlot index={4} />
                          <InputOTPSlot index={5} />
                        </InputOTPGroup>
                      </InputOTP>
                    </div>
                  </div>
                </SheetContent>
              </Sheet>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>id</TableHead>
                    <TableHead>Total Amount</TableHead>
                    <TableHead>Total Payouts</TableHead>
                    <TableHead className="text-right">Action</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {distributionsToApprove?.map((distribution, idx) => (
                    <TableRow key={idx}>
                      <TableCell>D{distribution.id}</TableCell>
                      <TableCell>
                        ${formatNumber(distribution.totalAmount)}
                      </TableCell>
                      <TableCell>{distribution.transactions.length}</TableCell>
                      <TableCell className="text-right">
                        {userRole !== AdminRole.User && (
                          <Button
                            variant="outline"
                            size="sm"
                            className="bg-green-500"
                            onClick={async () => {
                              if (has2fa) {
                                setDistributionId(distribution.id);
                              } else {
                                toast({
                                  title: "Error",
                                  description: "Please setup MFA in settings",
                                  variant: "destructive",
                                });
                              }
                            }}
                          >
                            Approve
                          </Button>
                        )}
                        {(userRole !== AdminRole.User ||
                          userData?.getUser.userId ===
                            distribution.creater) && (
                          <Button
                            variant="outline"
                            size="sm"
                            className="bg-red-500"
                            onClick={async () => {
                              await reject({ id: distribution.id });
                            }}
                          >
                            Reject
                          </Button>
                        )}
                        <Button
                          variant="outline"
                          size="sm"
                          className="bg-black text-white"
                          onClick={() => {
                            navigate(`/distribution/${distribution.id}`);
                          }}
                        >
                          See Details
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        )}
        <Card className="xl:col-span-2">
          <CardHeader className="flex flex-row items-center justify-between">
            <div className="grid gap-2">
              <CardTitle>Transactions</CardTitle>
              <CardDescription>Recent transactions</CardDescription>
            </div>
            <Button
              variant="outline"
              className="bg-black text-white"
              onClick={() => navigate("/upload")}
            >
              Upload Batch
            </Button>
            <Button
              variant="outline"
              className="bg-black text-white"
              onClick={() => {
                const history = transactionsData;
                if (!history) return;
                transactionHistoryToCSV(history, transactionPage);
              }}
            >
              Download CSV
            </Button>
          </CardHeader>
          <CardContent>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>id</TableHead>
                  <TableHead>Type</TableHead>
                  <TableHead>Status</TableHead>
                  <TableHead>Date</TableHead>
                  <TableHead>Withdrawal</TableHead>
                  <TableHead>Deposit</TableHead>
                  <TableHead className="text-right">Balance</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {transactions?.map((transaction, idx) => (
                  <TableRow
                    key={idx}
                    onClick={() => {
                      if (transaction.type === TransactionType.Distribution) {
                        navigate(`/distribution/${transaction.id}`);
                        return;
                      }
                      // open in new tab
                      if (!transaction.txHash) return;
                      window.open(
                        `${process.env.REACT_APP_ETH_EXPLORER_URL}${transaction.txHash}`,
                        "_blank"
                      );
                    }}
                  >
                    <TableCell>
                      {transaction.type === TransactionType.Distribution
                        ? "D"
                        : "T"}
                      {transaction.id}
                    </TableCell>
                    <TableCell>
                      <Badge className="text-xs" variant="outline">
                        {transaction.type}
                      </Badge>
                    </TableCell>
                    <TableCell>
                      <Badge className="text-xs" variant="outline">
                        <div
                          style={{
                            height: 10,
                            width: 10,
                            borderRadius: "50%",
                            marginRight: 4,
                            background:
                              transaction.status === "CANCELLED"
                                ? "grey"
                                : transaction.status === "PROCESSED"
                                ? "green"
                                : "yellow",
                          }}
                        />
                        {transaction.status === "CANCELLED"
                          ? "Canceled"
                          : transaction.isProcessed
                          ? "Processed"
                          : "Pending"}
                      </Badge>
                    </TableCell>
                    <TableCell>{formatDate(transaction.date)}</TableCell>
                    <TableCell>
                      {transaction.type === TransactionType.Distribution &&
                      transaction.isProcessed
                        ? `$${formatNumber(transaction.creditDelta)}`
                        : "-"}
                    </TableCell>
                    <TableCell>
                      {transaction.type === TransactionType.Topup
                        ? `$${formatNumber(transaction.creditDelta)}`
                        : "-"}
                    </TableCell>
                    <TableCell className="text-right">
                      {transaction.isProcessed
                        ? `$${formatNumber(transaction.credit)}`
                        : "-"}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Pagination>
              <PaginationContent>
                <PaginationItem>
                  <PaginationPrevious
                    onClick={() => {
                      if (transactionPage === 1) return;
                      setTransactionPage(transactionPage - 1);
                    }}
                  />
                </PaginationItem>
                <PaginationItem>
                  <PaginationLink>{transactionPage}</PaginationLink>
                </PaginationItem>
                <PaginationItem>
                  <PaginationNext
                    onClick={() => {
                      if (!transactions?.length) return;
                      setTransactionPage(transactionPage + 1);
                    }}
                  />
                </PaginationItem>
              </PaginationContent>
            </Pagination>
          </CardContent>
        </Card>
        {/* </div> */}
      </main>
      <Toaster />
    </div>
  );
}

const transactionHistoryToCSV = (
  transactions: GetTransactionsHistoryQuery,
  page: number
) => {
  const csv = transactions.getTransactionsHistory
    .map((t) => {
      return {
        id: (t.type === TransactionType.Distribution ? "D" : "T") + t.id,
        type: t.type,
        status:
          t.status === "CANCELLED"
            ? "Canceled"
            : t.isProcessed
            ? "Processed"
            : "Pending",
        date: formatDate(t.date),
        withdrawal:
          t.type === TransactionType.Distribution && t.isProcessed
            ? `$${formatNumber(t.creditDelta)}`
            : "-",
        deposit:
          t.type === TransactionType.Topup
            ? `$${formatNumber(t.creditDelta)}`
            : "-",
        balance: t.isProcessed ? `$${formatNumber(t.credit)}` : "-",
        fee: t.fee ?? `$${get50BpsAndFormat(t.creditDelta)}`,
      };
    })
    .map((t) => Object.values(t).join(","));

  csv.unshift("id,type,status,date,withdrawal,deposit,balance,fee");

  const csvString = csv.join("\n");

  const csvData = new Blob([csvString], {
    type: "text/csv;charset=utf-8",
  });
  const csvUrl = URL.createObjectURL(csvData);
  const a = document.createElement("a");
  a.href = csvUrl;
  a.download = `txHistory-${page}.csv`;
  a.click();
  URL.revokeObjectURL(csvUrl);
};
