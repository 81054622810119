export const formatNumber = (hexValue: string, decimals = 6) => {
  // Convert the hex value to a BigInt
  const valueBigInt = BigInt(hexValue);
  // Convert to a human-readable format using decimals
  const factor = BigInt(10 ** decimals);
  const formattedValue = Number(valueBigInt) / Number(factor);
  return formattedValue.toFixed(2);
};

export const get50BpsAndFormat = (hexValue: string, decimal = 6) => {
  // Convert the hex value to a BigInt
  const valueBigInt = BigInt(hexValue);
  const fiftyBps = BigInt(50);
  const value = (valueBigInt * fiftyBps) / BigInt(10000);
  return formatNumber(value.toString(), decimal);
};
